import axios from '@/plugins/axios';
import { processAxiosException } from '@/plugins/mixin';

export default {
  async getImages({ dispatch, rootGetters }, { hashData }) {
    let imageList = [];
    await axios
      .post(`${rootGetters.alysonApi}/images`, {
        data: hashData,
      })
      .then(({ data }) => {
        imageList = data.records.map((item) => {
          const match = item.url.match(/image.+/);
          const url = match ? `${rootGetters.alysonApi}/${match[0]}` : '';
          return { url, src: item.src };
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    return imageList;
  },
};
