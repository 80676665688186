import moment from 'moment';

import axios from '@/plugins/axios';
import { getParamsWithSerializer, processAxiosException } from '@/plugins/mixin';

const actions = {
  async getStatus({ commit, dispatch, rootGetters }) {
    commit('changeState', { statusIsLoading: true });
    await axios
      .get(`${rootGetters.wolkeAPI}/status`)
      .then(({ data }) => {
        commit('updateCompositeState', {
          itemKey: 'settings',
          changed: { status: data.status },
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { statusIsLoading: false });
  },

  async getConfig({ commit, dispatch, rootGetters }) {
    commit('changeState', { configIsLoading: true });
    await axios
      .get(`${rootGetters.wolkeAPI}/config`)
      .then(({ data }) => {
        commit('updateCompositeState', {
          itemKey: 'settings',
          changed: { config: data.config },
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { configIsLoading: false });
  },

  async getWorkers({ commit, dispatch, rootGetters }) {
    commit('changeState', { workersAreLoading: true });
    await axios
      .get(`${rootGetters.wolkeAPI}/crawler`)
      .then(({ data }) => {
        commit('changeState', { workers: data.records });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { workersAreLoading: false });
  },

  async getCrawlerStat({ commit, dispatch, rootGetters }) {
    let stat = {};
    commit('changeState', { areCrawlerStatLoading: true });
    await axios
      .get(`${rootGetters.wolkeAPI}/crawler/stat`)
      .then(({ data }) => {
        const { updated } = data.crawler_stat;
        stat = {
          ...data.crawler_stat,
          updated: updated ? moment(updated).format('YYYY-MM-DD HH:mm:ss') : null,
        };
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      })
      .finally(() => {
        commit('changeState', {
          areCrawlerStatLoading: false,
          crawlerStat: stat,
        });
      });
  },

  async lockSeveralFarms({ dispatch, rootGetters }, { crawlers, lock }) {
    await axios
      .post(`${rootGetters.wolkeAPI}/crawler/lock`, {
        crawlers,
        lock,
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
  },

  async killCrawler({ dispatch, rootGetters }, { crawlers }) {
    await axios.post(`${rootGetters.wolkeAPI}/crawler/kill`, { crawlers }).catch((error) => {
      dispatch('alert/setError', processAxiosException(error), {
        root: true,
      });
    });
  },

  async getWolkePortals({ commit, dispatch, rootGetters }) {
    commit('changeState', { wolkePortalsAreLoading: true });
    await axios
      .get(`${rootGetters.wolkeAPI}/portal`)
      .then(({ data }) => {
        commit('changeState', {
          wolkePortals: data.records.map((item) => {
            const portal = item;
            Object.entries(portal).forEach(([key, val]) => {
              if (key.indexOf('_time') !== -1 && key.indexOf('operation') === -1) {
                portal[key] = val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : null;
              }
            });
            return portal;
          }),
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { wolkePortalsAreLoading: false });
  },

  async runPortalAction(
    { commit, dispatch, rootGetters },
    { portals, command, isTest, alsoForProtected }
  ) {
    commit('changeState', { wolkePortalsAreLoading: true });
    await axios
      .post(`${rootGetters.wolkeAPI}/portal/action`, {
        portals,
        command,
        is_test: isTest,
        is_protected: alsoForProtected,
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { wolkePortalsAreLoading: false });
  },

  async getPortalItems({ commit, dispatch, rootGetters }, { portal }) {
    commit('changeState', { portalItemsLoading: true, portalItems: [] });
    await axios
      .get(`${rootGetters.wolkeAPI}/portal/${portal}/starts`)
      .then(({ data }) => {
        commit('changeState', {
          portalItems: data.records.map((item) => ({
            ...item,
            start_time: item.start_time
              ? moment(item.start_time).format('YYYY-MM-DD HH:mm:ss')
              : null,
            finish_time: item.finish_time
              ? moment(item.finish_time).format('YYYY-MM-DD HH:mm:ss')
              : null,
          })),
          portalMaxMarkers: data.max_markers,
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { portalItemsLoading: false });
  },

  async getPortalCurrentStats({ commit, dispatch, rootGetters }, { portal }) {
    commit('changeState', {
      areCurrentStatsLoading: true,
      portalCurrentStats: [],
    });
    await axios
      .get(`${rootGetters.wolkeAPI}/portal/${portal}/current`)
      .then(({ data }) => {
        const getFormattedDateTime = (input) =>
          moment(input).isValid() ? moment(input).format('YYYY-MM-DD HH:mm:ss') : null;
        const rawStats = data;
        commit('changeState', {
          portalCurrentStats: {
            ...rawStats,
            start_time: getFormattedDateTime(rawStats.start_time),
            finish_time: getFormattedDateTime(rawStats.finish_time),
            test_start_time: getFormattedDateTime(rawStats.test_start_time),
            test_finish_time: getFormattedDateTime(rawStats.test_finish_time),
          },
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { areCurrentStatsLoading: false });
  },

  async getPortalStats({ commit, dispatch, rootGetters }, { portal, dateFrom, dateTo, type }) {
    commit('updateCompositeState', {
      itemKey: 'portalStatsAreLoading',
      changed: { [type]: true },
    });
    await axios
      .get(`${rootGetters.wolkeAPI}/stats`, {
        params: {
          portal,
          date_from: dateFrom,
          date_to: dateTo,
          type,
        },
      })
      .then(({ data }) => {
        commit('updateCompositeState', {
          itemKey: 'portalStats',
          changed: {
            [type]: data.records.map((item) => {
              const current = item;
              current.dt = item.dt ? moment(item.dt).format('YYYY-MM-DD') : '';
              if (type === 'priority') current.unikey = current.dt + current.priority;
              return current;
            }),
          },
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('updateCompositeState', {
      itemKey: 'portalStatsAreLoading',
      changed: { [type]: false },
    });
  },

  async getRecentPortalStats({ dispatch, rootGetters }, { portal, dateFrom, type }) {
    let stats = [];
    await axios
      .get(`${rootGetters.wolkeAPI}/stats`, {
        params: { portal, date_from: dateFrom, type },
      })
      .then(({ data }) => {
        stats = data.records.map((item) => {
          const current = item;
          current.dt = item.dt ? moment(item.dt).format('YYYY-MM-DD') : '';
          if (type === 'priority') current.unikey = current.dt + current.priority;
          return current;
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    return stats;
  },

  async getStarts(
    { commit, dispatch, rootGetters },
    { id, regex, parent, portal, worker, status, priority, isRetried, sorter, limit, page } = {}
  ) {
    commit('changeState', { startsListIsLoading: true });
    await axios
      .get(
        `${rootGetters.wolkeAPI}/start`,
        getParamsWithSerializer(
          {
            id,
            regex,
            parent,
            portal,
            worker,
            status,
            priority,
            is_retried: isRetried,
            sorter,
            limit: limit || 1000,
            page,
          },
          'id'
        )
      )
      .then(({ data }) => {
        commit('changeState', {
          startsList: data.records.map((item) => {
            const current = item;
            current.created = item.created
              ? moment(item.created).format('YYYY-MM-DD HH:mm:ss')
              : '';
            current.started = item.started
              ? moment(item.started).format('YYYY-MM-DD HH:mm:ss')
              : '';
            current.finished = item.finished
              ? moment(item.finished).format('YYYY-MM-DD HH:mm:ss')
              : '';
            current.unikey = `${item.id}:${item.parent || ''}`;
            return current;
          }),
          startListTotalItems: data.total_record,
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { startsListIsLoading: false });
  },

  async startRe({ commit, dispatch, rootGetters }, { markers }) {
    commit('changeState', { startsListIsLoading: true });
    await axios.post(`${rootGetters.wolkeAPI}/start/restart`, { markers }).catch((error) => {
      dispatch('alert/setError', processAxiosException(error), {
        root: true,
      });
    });
    commit('changeState', { startsListIsLoading: false });
  },

  async getPortalsStatistics({ commit, dispatch, rootGetters }, { dateFrom }) {
    commit('changeState', { isPortalStatisticsLoading: true });
    await axios
      .get(`${rootGetters.wolkeAPI}/stats/portals`, {
        params: { date: dateFrom },
      })
      .then(({ data }) => {
        commit('changeState', {
          portalsStatistics: data.records.map((item) => {
            const current = item;
            current.dt = moment(item.dt).format('YYYY-MM-DD');
            return current;
          }),
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { isPortalStatisticsLoading: false });
  },

  async getServices({ commit, dispatch, rootGetters }) {
    commit('changeState', { serviceAreLoading: true });
    await axios
      .get(`${rootGetters.wolkeAPI}/service`)
      .then(({ data }) => {
        commit('changeState', { services: data.records });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { serviceAreLoading: false });
  },

  async callServiceAction({ dispatch, rootGetters }, { command, services }) {
    await axios
      .post(`${rootGetters.wolkeAPI}/service/action`, { command, services })
      .catch((error) => {
        const { status, response, responseURL } = error.request;
        dispatch('alert/setError', { status, response, responseURL }, { root: true });
      });
    dispatch('getServices');
  },

  async getPackages({ commit, dispatch, rootGetters }) {
    commit('changeState', { packagesAreLoading: true });
    await axios
      .get(`${rootGetters.wolkeAPI}/packages`)
      .then(({ data }) => {
        commit('changeState', {
          packages: data.records.map((item) => {
            const current = item;
            current.updated = moment(item.updated).format('YYYY-MM-DD HH:mm:ss');
            return current;
          }),
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { packagesAreLoading: false });
  },
};

export default actions;
