import moment from 'moment';

import axios from '@/plugins/axios';
import { getParamsWithSerializer, processAxiosException } from '@/plugins/mixin';

const actions = {
  async getLeipzigGeneralStat(
    { commit, dispatch, rootGetters },
    { filter, sorter, limit, page } = {}
  ) {
    commit('changeState', { isLeipzigStatLoading: true });
    await axios
      .get(
        `${rootGetters.feApi}/analytic/generalstat`,
        getParamsWithSerializer({
          filter,
          sorter,
          limit: limit || 100,
          page,
          project: 'auto',
        })
      )
      .then(({ data }) => {
        commit('changeState', {
          leipzigStat: data.records.map((item) => {
            const object = item;
            object.updated = item.updated ? moment(item.updated).format('YYYY-MM-DD HH:mm:ss') : '';
            object.matching_dt = item.matching_dt
              ? moment(item.matching_dt).format('YYYY-MM-DD')
              : '';
            return object;
          }),
          leipzigStatTotalItems: data.total_record,
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { isLeipzigStatLoading: false });
  },

  async getLeipzigStatByPortal({ commit, dispatch, rootGetters }, { country }) {
    commit('changeState', { isLeipzigStatByPortalLoading: true });
    await axios
      .get(`${rootGetters.feApi}/analytic/portalstat`, {
        params: { project: 'auto', country },
      })
      .then(({ data }) => {
        commit('changeState', {
          leipzigStatByPortal: data.records.map((item) => {
            const object = item;
            object.start = item.start ? moment(item.start).format('YYYY-MM-DD') : '';
            object.finish = item.finish ? moment(item.finish).format('YYYY-MM-DD') : '';
            return object;
          }),
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { isLeipzigStatByPortalLoading: false });
  },

  async getLeipzigStatByDealerType({ commit, dispatch, rootGetters }, { country }) {
    commit('changeState', { isLeipzigStatByDealerTypeLoading: true });
    await axios
      .get(`${rootGetters.feApi}/analytic/dealertypestat`, {
        params: { project: 'auto', country },
      })
      .then(({ data }) => {
        commit('changeState', { leipzigStatByDealerType: data.records });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { isLeipzigStatByDealerTypeLoading: false });
  },

  async updateLeipzigStat({ dispatch, rootGetters }, { country }) {
    await axios
      .post(`${rootGetters.gwApi}/start`, {
        project: `leipzig_${country}`,
        pipeline: 'statistics/run.yml',
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
  },
};

export default actions;
