const state = {
  settings: {
    config: {},
    status: {},
  },
  configIsLoading: false,
  statusIsLoading: false,

  workerTableHeaders: [
    {
      text: 'crawler',
      value: 'name',
    },
    {
      text: '',
      value: 'data-table-expand',
    },
    {
      text: 'version (crawlers/crawlers-conf)',
      value: 'version',
    },
    {
      text: 'num_proc',
      value: 'num_proc',
      align: 'center',
    },
    {
      text: 'last status',
      value: 'last_status',
      align: 'center',
    },
    {
      text: 'last action',
      value: 'last_action',
      align: 'center',
    },
    {
      text: 'last ping',
      value: 'last_ping',
      align: 'center',
    },
  ],
  workers: [],
  workersAreLoading: false,

  crawlerStat: {},
  areCrawlerStatLoading: false,

  workerDetailsHeaders: [
    {
      text: 'marker',
      value: 'id',
    },
    {
      text: 'portal',
      value: 'portal',
    },
    {
      text: 'parent',
      value: 'parent',
    },
    {
      text: 'priority',
      value: 'priority',
      align: 'center',
    },
  ],

  wolkePortalsTableHeaders: [
    {
      text: 'portal',
      value: 'name',
    },
    {
      text: '',
      value: 'data-table-expand',
    },
    {
      text: 'group',
      value: 'group',
      align: 'center',
    },
    {
      text: 'max',
      align: 'center',
      value: 'max',
    },
    {
      text: 'status',
      align: 'center',
      value: 'status',
    },
    {
      text: 'priority',
      align: 'center',
      value: 'priority',
    },
    {
      text: 'test',
      align: 'center',
      value: 'test_status',
    },
    {
      text: 'markers',
      value: 'markers_cnt',
      align: 'center',
    },
    {
      text: 'test markers',
      value: 'markers_test_cnt',
      align: 'center',
    },
    {
      text: 'locked',
      value: 'locked',
      align: 'center',
    },
    {
      text: 'locked test',
      value: 'locked_test',
      align: 'center',
    },
    {
      text: 'is protected',
      value: 'is_protected',
      align: 'center',
    },
  ],
  wolkePortals: [],
  wolkePortalsAreLoading: false,

  portalItemsHeaders: [
    {
      text: 'priority',
      value: 'priority',
      align: 'center',
    },
    {
      text: 'remain',
      value: 'remain',
      align: 'center',
    },
    {
      text: 'in progress',
      value: 'in_progress',
      align: 'center',
    },
    {
      text: 'broken',
      value: 'broken',
      align: 'center',
    },
    {
      text: 'total',
      value: 'total',
      align: 'center',
    },
    {
      text: 'avg time',
      value: 'avg',
      align: 'center',
    },
    {
      text: 'median',
      value: 'median',
      align: 'center',
    },
    {
      text: 'started',
      value: 'start_time',
      align: 'center',
    },
    {
      text: 'finished',
      value: 'finish_time',
      align: 'center',
    },
  ],
  portalItems: [],
  portalMaxMarkers: 0,
  portalItemsLoading: false,

  portalCurrentStats: [],
  areCurrentStatsLoading: false,

  portalStats: {
    portal: [],
    priority: [],
  },
  portalStatsAreLoading: {
    portal: false,
    priority: false,
  },

  portalStatsHeaders: [
    {
      text: 'date',
      align: 'center',
      value: 'dt',
    },
    {
      text: 'total',
      align: 'center',
      value: 'total',
    },
    {
      text: 'unfinished',
      align: 'center',
      value: 'unfinished',
    },
    {
      text: 'broken',
      align: 'center',
      value: 'broken',
    },
    {
      text: 'crawling time',
      align: 'center',
      value: 'crawling_time',
    },
    {
      text: 'crawling test time',
      align: 'center',
      value: 'crawling_test_time',
    },
  ],
  priorityHeaders: [
    {
      text: 'date',
      align: 'center',
      value: 'dt',
    },
    {
      text: 'priority',
      align: 'center',
      value: 'priority',
    },
    {
      text: 'total',
      align: 'center',
      value: 'total',
    },
    {
      text: 'unfinished',
      align: 'center',
      value: 'unfinished',
    },
    {
      text: 'broken',
      align: 'center',
      value: 'broken',
    },
    {
      text: 'avg',
      align: 'center',
      value: 'avg',
    },
    {
      text: 'median',
      align: 'center',
      value: 'median',
    },
    {
      text: 'min duration',
      align: 'center',
      value: 'min_duration',
    },
    {
      text: 'max duration',
      align: 'center',
      value: 'max_duration',
    },
  ],

  portalStatus: {},
  portalStatusIsLoading: false,

  startsListHeaders: [
    {
      text: 'marker',
      value: 'id',
    },
    {
      text: '',
      value: 'data-table-expand',
    },
    {
      text: 'parent',
      value: 'parent',
    },
    {
      text: 'portal',
      value: 'portal',
    },
    {
      text: 'priority',
      value: 'priority',
      align: 'center',
    },
    {
      text: 'status',
      value: 'status',
      align: 'center',
    },
    {
      text: 'created',
      value: 'created',
      align: 'center',
    },
    {
      text: 'worker',
      value: 'worker',
    },
    {
      text: 'started',
      value: 'started',
      align: 'center',
    },
    {
      text: 'finished',
      value: 'finished',
      align: 'center',
    },
    {
      text: 'is broken',
      value: 'is_broken',
      align: 'center',
    },
    {
      text: 'is retried',
      value: 'is_retried',
      align: 'center',
    },
  ],
  startsList: [],
  startListTotalItems: 0,
  startsListIsLoading: false,

  portalsStatistics: [],
  isPortalStatisticsLoading: false,

  servicesHeaders: [
    {
      text: 'name',
      value: 'name',
      align: 'center',
    },
    {
      text: 'group',
      value: 'group',
      align: 'center',
    },
    {
      text: 'status',
      value: 'status',
      align: 'center',
    },
    {
      text: 'provider',
      value: 'provider',
      align: 'center',
    },
    {
      text: 'pillar',
      value: 'pillar',
      align: 'center',
    },
    {
      text: 'last ping',
      value: 'last_ping',
      align: 'center',
    },
    {
      text: 'last status',
      value: 'last_status',
      align: 'center',
    },
    {
      text: 'start',
      value: 'start',
      align: 'center',
    },
    {
      text: 'finish',
      value: 'finish',
      align: 'center',
    },
  ],
  services: [],
  serviceAreLoading: false,

  packagesHeaders: [
    {
      text: 'name',
      value: 'name',
      align: 'left',
    },
    {
      text: 'version',
      value: 'version',
      align: 'right',
    },
    {
      text: 'updated',
      value: 'updated',
      align: 'center',
    },
  ],
  packages: [],
  packagesAreLoading: false,
};

export default state;
