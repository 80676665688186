import axios from '@/plugins/axios';
import { processAxiosException, getParamsWithSerializer } from '@/plugins/mixin';

const actions = {
  async getPortals({ commit, dispatch, rootGetters }, { dt }) {
    commit('changeState', { arePortalsLoading: true });
    await axios
      .get(`${rootGetters.feApi}/fields2/portal`, getParamsWithSerializer({ dt }))
      .then(({ data }) => {
        commit('changeState', {
          portals: data.records,
          fields: [],
          values: {},
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { arePortalsLoading: false });
  },

  async getFields({ commit, dispatch, rootGetters }, { portal, dt }) {
    commit('changeState', { areFieldsLoading: true });
    await axios
      .get(`${rootGetters.feApi}/fields2/field`, getParamsWithSerializer({ portal, dt }))
      .then(({ data }) => {
        const values = {};
        const areValuesLoading = {};
        const fields = data.records.map((item) => {
          // eslint-disable-next-line no-param-reassign
          if (item.value_filled) item.value_filled = parseFloat(item.value_filled).toFixed(2);
          values[item.name_id] = [];
          areValuesLoading[item.name_id] = false;
          return item;
        });
        commit('changeState', { fields, values, areValuesLoading });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { areFieldsLoading: false });
  },

  async getValues({ commit, dispatch, rootGetters }, { nameId, dt }) {
    commit('updateCompositeState', {
      itemKey: 'areValuesLoading',
      changed: { [nameId]: true },
    });
    await axios
      .get(`${rootGetters.feApi}/fields2/value`, getParamsWithSerializer({ name_id: nameId, dt }))
      .then(({ data }) => {
        commit('updateCompositeState', {
          itemKey: 'values',
          changed: { [nameId]: data.records || [] },
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('updateCompositeState', {
      itemKey: 'areValuesLoading',
      changed: { [nameId]: false },
    });
  },

  async getObjIds({ dispatch, rootGetters }, { valueId, dt, limit = 5 }) {
    let objIds = [];
    await axios
      .get(
        `${rootGetters.feApi}/fields2/value/obj`,
        getParamsWithSerializer({ value_id: valueId, dt, limit })
      )
      .then(({ data }) => {
        objIds = data.obj_ids;
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    return objIds;
  },
};

export default actions;
