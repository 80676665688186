const state = {
  portalHeaders: [
    {
      text: 'Portal',
      value: 'portal',
      align: 'left',
    },
    {
      text: 'Count of fields',
      value: 'field_count',
      align: 'center',
    },
    {
      text: 'Used in spider',
      value: 'use_field_count',
      align: 'center',
    },
  ],
  portals: [],
  arePortalsLoading: false,

  fieldHeaders: [
    {
      text: 'Name',
      value: 'name',
      align: 'left',
    },
    {
      text: '',
      sortable: false,
      value: 'data-table-expand',
    },
    {
      text: 'Object type',
      value: 'obj_type',
      align: 'left',
    },
    {
      text: 'Item',
      value: 'item_name',
      align: 'left',
    },
    {
      text: 'Item ID',
      value: 'name_id',
      align: ' d-none',
    },
    {
      text: 'Count of values',
      value: 'value_count',
      align: 'right',
    },
    {
      text: 'Filled, %',
      value: 'value_filled',
      align: 'right',
    },
  ],
  fields: [],
  areFieldsLoading: false,

  valuesHeaders: [
    {
      text: 'Value',
      value: 'value',
      align: 'left',
    },
    {
      text: 'Count of objects',
      value: 'value_obj_count',
      align: 'center',
    },
    {
      text: 'Value ID',
      value: 'value_id',
      align: ' d-none',
    },
    {
      text: 'Obj IDs',
      value: 'obj_ids',
      align: 'center',
    },
    {
      text: 'actions',
      align: 'center',
      sortable: false,
      value: 'actions',
    },
  ],
  values: {},
  areValuesLoading: {},
};

export default state;
