import Vue from 'vue';
import VueRouter from 'vue-router';
import npmConfig from '../../package.json';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: { path: '/current-status' },
  },
  {
    path: '/general-tools',
    component: () => import('../components/BaseRoute.vue'),
    redirect: { path: '/current-status' },
    meta: { title: 'General tools', icon: 'mdi-tools' },
    children: [
      {
        path: '/current-status',
        component: () => import('../views/CurrentStatus.vue'),
        props: (route) => ({
          search: route.query.search,
          regexp: route.query.regexp,
          page: route.query.page,
        }),
        meta: { title: 'Current Status', icon: 'mdi-table-cog' },
      },
      {
        path: '/adressbuch',
        component: () => import('../views/Adressbuch.vue'),
        props: (route) => ({
          search: route.query.search,
          regexp: route.query.regexp,
          page: route.query.page,
        }),
        meta: { title: 'Adressbuch', icon: 'mdi-inbox-full' },
      },
      {
        path: '/zoowarter',
        component: () => import('../views/Zoowarter.vue'),
        props: (route) => ({
          search: route.query.search,
          page: route.query.page,
        }),
        meta: { title: 'Zoowärter', icon: 'mdi-tools', invisible: true },
      },
      {
        path: '/zoowarter2',
        component: () => import('../views/Zoowarter.vue'),
        meta: {
          title: 'Zoowärter',
          icon: 'mdi-tools',
          redirect: 'https://lothub.lotint.net/v2/general-tools/zoowarter',
        },
      },
      {
        path: '/fields',
        component: () => import('../views/Fields2.vue'),
        props: (route) => ({
          search: route.query.search,
          page: route.query.page,
        }),
        meta: { title: 'Fields', icon: 'mdi-checkbook', invisible: true },
      },
      {
        path: '/fields2',
        component: () => import('../views/Fields2.vue'),
        meta: {
          title: 'Fields',
          icon: 'mdi-checkbook',
          redirect: 'https://lothub.lotint.net/v2/general-tools/fields',
        },
      },
      {
        path: '/alyson',
        component: () => import('../views/Alyson.vue'),
        meta: { title: 'Alyson', icon: 'mdi-image-multiple-outline', invisible: true },
      },
      {
        path: '/alyson2',
        component: () => import('../views/Alyson.vue'),
        meta: {
          title: 'Alyson',
          icon: 'mdi-image-multiple-outline',
          redirect: 'https://lothub.lotint.net/v2/general-tools/alyson',
        },
      },
    ],
  },
  {
    path: '/wolke',
    component: () => import('../components/BaseRoute.vue'),
    redirect: { path: '/wolke-portals' },
    meta: { title: 'Wolke tools', icon: 'mdi-cog' },
    children: [
      {
        path: '/wolke-portals',
        component: () => import('../views/WolkePortals.vue'),
        props: (route) => ({
          search: route.query.search,
          regexp: route.query.regexp,
          page: route.query.page,
        }),
        meta: { title: 'Portals', icon: 'mdi-format-list-bulleted-type', invisible: true },
      },
      {
        path: '/wolke-portals2',
        component: () => import('../views/WolkePortals.vue'),
        meta: {
          title: 'Portals',
          icon: 'mdi-format-list-bulleted-type',
          redirect: 'https://lothub.lotint.net/v2/general-tools/portals',
        },
      },
      {
        path: '/wolke-farms',
        component: () => import('../views/WolkeFarms.vue'),
        props: (route) => ({
          search: route.query.search,
          regexp: route.query.regexp,
          page: route.query.page,
        }),
        meta: { title: 'Farms', icon: 'mdi-format-list-bulleted-type', invisible: true },
      },
      {
        path: '/wolke-farms2',
        component: () => import('../views/WolkeFarms.vue'),
        meta: {
          title: 'Farms',
          icon: 'mdi-format-list-bulleted-type',
          redirect: 'https://lothub.lotint.net/v2/general-tools/crawler',
        },
      },
      {
        path: '/wolke-services',
        component: () => import('../views/WolkeServices.vue'),
        meta: { title: 'Services', icon: 'mdi-star-cog', invisible: true },
      },
      {
        path: '/wolke-services2',
        component: () => import('../views/WolkeServices.vue'),
        meta: {
          title: 'Services',
          icon: 'mdi-star-cog',
          redirect: 'https://lothub.lotint.net/v2/general-tools/services',
        },
      },
      {
        path: '/wolke-starts',
        component: () => import('../views/WolkeStarts.vue'),
        props: (route) => ({
          page: route.query.page,
          marker: route.query.marker,
          regex: route.query.regex,
          parent: route.query.parent,
          worker: route.query.worker,
          portal: route.query.portal,
          status: route.query.status,
          priority: route.query.priority,
          isRetried: route.query.is_retried,
        }),
        meta: { title: 'Starts', icon: 'mdi-format-list-bulleted-type', invisible: true },
      },
      {
        path: '/wolke-starts2',
        component: () => import('../views/WolkeStarts.vue'),
        meta: {
          title: 'Starts',
          icon: 'mdi-format-list-bulleted-type',
          redirect: 'https://lothub.lotint.net/v2/general-tools/starts',
        },
      },
      {
        path: '/wolke-statistic',
        component: () => import('../views/WolkeStatistic.vue'),
        props: (route) => ({
          portal: route.query.portal,
          dateFrom: route.query.datefrom,
          dateTo: route.query.dateto,
          type: route.query.type,
        }),
        meta: { title: 'Statistic', icon: 'mdi-format-list-bulleted-type', invisible: true },
      },
      {
        path: '/wolke-statistic2',
        component: () => import('../views/WolkeStatistic.vue'),
        meta: {
          title: 'Statistic',
          icon: 'mdi-format-list-bulleted-type',
          redirect: 'https://lothub.lotint.net/v2/general-tools/statistic',
        },
      },
      {
        path: '/wolke-portals-statistics',
        component: () => import('../views/WolkePortalsStatistics.vue'),
        props: (route) => ({ date: route.query.datefrom }),
        meta: {
          title: 'Portals statistics',
          icon: 'mdi-format-list-bulleted-type',
          invisible: true,
        },
      },
      {
        path: '/wolke-portals-statistics2',
        component: () => import('../views/WolkePortalsStatistics.vue'),
        meta: {
          title: 'Portals statistics',
          icon: 'mdi-format-list-bulleted-type',
          redirect: 'https://lothub.lotint.net/v2/general-tools/general-crawling-stats',
        },
      },
    ],
  },
  {
    path: '/daily-report',
    component: () => import('../components/BaseRoute.vue'),
    redirect: { path: '/object-count' },
    meta: { title: 'Daily Reports', icon: 'mdi-database-search' },
    children: [
      {
        path: '/object-count',
        component: () => import('../views/ObjectCount.vue'),
        props: (route) => ({
          portal: route.query.portal,
          exportNum: route.query.exportnum,
          dateFrom: route.query.datefrom,
          dateTo: route.query.dateto,
        }),
        meta: { title: 'Object count', icon: 'mdi-city' },
      },
      {
        path: '/customer-count',
        component: () => import('../views/CustomerCount.vue'),
        props: (route) => ({
          portal: route.query.portal,
          exportNum: route.query.exportnum,
          dateFrom: route.query.datefrom,
          dateTo: route.query.dateto,
        }),
        meta: { title: 'Customer count', icon: 'mdi-contacts' },
      },
    ],
  },
  {
    path: '/mapping',
    component: () => import('../components/BaseRoute.vue'),
    redirect: { path: '/lookups' },
    meta: {
      title: 'Mapping',
      icon: 'mdi-inbox-full',
      status: 'mdi-ab-testing',
    },
    children: [
      {
        path: '/lookups',
        component: () => import('../views/MappingLookups.vue'),
        props: (route) => ({
          name: route.query.search,
          regexp: route.query.regexp,
          project: route.query.project,
          lookup: route.query.lookup,
          page: route.query.page,
        }),
        meta: {
          title: 'Lookups',
          icon: 'mdi-inbox-full',
          redirect: 'https://lothub.lotint.net/v2/mapping/lookups',
        },
      },
      {
        path: '/originals',
        component: () => import('../views/MappingOriginals.vue'),
        props: (route) => ({
          provider: route.query.provider,
          dt: route.query.dt,
          project: route.query.project,
          lookup: route.query.lookup,
          regex: route.query.regex,
        }),
        meta: { title: 'Originals', icon: 'mdi-inbox-full' },
      },
    ],
  },
  {
    path: '/analytics',
    component: () => import('../components/BaseRoute.vue'),
    redirect: { path: '/leipzig-stat' },
    meta: { title: 'Analytics', icon: 'mdi-school', status: 'mdi-ab-testing' },
    children: [
      {
        path: '/leipzig-stat',
        component: () => import('../views/LeipzigStat.vue'),
        props: (route) => ({
          search: route.query.search,
          page: route.query.page,
        }),
        meta: { title: 'Leipzig Statistic', icon: 'mdi-car' },
      },
    ],
  },
  {
    path: '/notifications',
    component: () => import('../views/Notifications.vue'),
    meta: { title: 'Notifications', icon: 'mdi-bell' },
  },
  {
    path: '/packages',
    component: () => import('../views/Packages.vue'),
    meta: { title: 'Packages', icon: 'mdi-package-variant', invisible: true },
  },
  {
    path: '/packages2',
    component: () => import('../views/Packages.vue'),
    meta: {
      title: 'Packages',
      icon: 'mdi-package-variant',
      redirect: 'https://lothub.lotint.net/v2/info/packages',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
    ? `${npmConfig.description} - ${to.meta.title}`
    : npmConfig.description;
  next();
});

export default router;
