import moment from 'moment';

import axios from '@/plugins/axios';
import {
  getParamsWithSerializer,
  reformRecords,
  processAxiosException,
  isEmpty,
  RequestError,
} from '@/plugins/mixin';

const stringifyNonEmpty = (value) => (value && value.length ? JSON.stringify(value) : '');

const reformRules = (data) =>
  reformRecords(data, {
    keywords: stringifyNonEmpty,
    matches: stringifyNonEmpty,
    excepts: stringifyNonEmpty,
    positives: stringifyNonEmpty,
    negatives: stringifyNonEmpty,
    dt: (value) => (value ? moment(value).format('YYYY-MM-DD') : ''),
  });

const reformOriginals = (data) =>
  reformRecords(data, {
    requirements: (value) => (isEmpty(value) ? null : JSON.stringify(value)),
    keywords: (value) => (value && value.length ? JSON.stringify(value) : ''),
    dt: (value) => (value ? moment(value).format('YYYY-MM-DD') : ''),
  });

export default {
  async getLookups(
    { commit, dispatch, rootGetters, rootState },
    { filter, sorter, limit, page, regex, lookup, project }
  ) {
    let result = [];
    let total = 0;
    let testResult = null;
    commit('changeState', { areLookupsLoading: true });
    await axios
      .get(
        `${rootGetters.feApi}/mapping/lookups`,
        getParamsWithSerializer({
          filter,
          sorter,
          limit,
          page,
          regex,
          lookup,
          project,
          invoke_test: rootState.mapping.invokeLookupsTests,
        })
      )
      .then(({ data }) => {
        total = data.total_record;
        result = data.records.lookups.map((item) => ({
          ...item,
          dt: moment(item.dt).isValid() ? moment(item.dt).format('YYYY-MM-DD') : '',
          requirements: isEmpty(item.requirements) ? null : JSON.stringify(item.requirements),
        }));
        testResult = data.records.test_response;
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), { root: true });
      })
      .finally(() => {
        commit('changeState', {
          areLookupsLoading: false,
          lookupsTotalItems: total,
          lookups: result,
          allLookupsTest: testResult,
          invokeLookupsTests: false,
        });
      });
  },

  async getLookupNames({ commit, dispatch, rootGetters }) {
    commit('changeState', { areLookupNamesLoading: true });
    let result = [];
    await axios
      .get(`${rootGetters.feApi}/mapping/lookups/names`)
      .then(({ data }) => {
        result = data;
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), { root: true });
      })
      .finally(() => {
        commit('changeState', {
          areLookupNamesLoading: false,
          lookupNames: result,
        });
      });
  },

  async getLookupProjects({ commit, dispatch, rootGetters }) {
    commit('changeState', { areLookupProjectsLoading: true });
    let result = [];
    await axios
      .get(`${rootGetters.feApi}/mapping/lookups/projects`)
      .then(({ data }) => {
        result = data;
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), { root: true });
      })
      .finally(() => {
        commit('changeState', {
          areLookupProjectsLoading: false,
          lookupProjects: result,
        });
      });
  },

  async getOriginals(
    { commit, dispatch, rootGetters },
    { filter, sorter, limit, page, regex, lookup, name }
  ) {
    let result = [];
    let total = 0;
    commit('changeState', { areOriginalsLoading: true });
    await axios
      .get(
        `${rootGetters.feApi}/mapping/originals`,
        getParamsWithSerializer({
          filter,
          sorter,
          limit,
          page,
          regex,
          lookup,
          name,
        })
      )
      .then(({ data }) => {
        total = data.total_record;
        result = reformOriginals(data.records).map((item) => {
          // eslint-disable-next-line no-param-reassign
          item.key = [item.lookup, item.provider, item.name, item.dt].join(':');
          return item;
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), { root: true });
      })
      .finally(() => {
        commit('changeState', {
          areOriginalsLoading: false,
          originalsTotalItems: total,
          originals: result,
        });
      });
  },

  async getOriginalDates({ commit, dispatch, rootGetters }) {
    commit('changeState', { areOriginalDatesLoading: true });
    let result = [];
    await axios
      .get(`${rootGetters.feApi}/mapping/originals/dt`)
      .then(({ data }) => {
        result = data;
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), { root: true });
      })
      .finally(() => {
        commit('changeState', {
          areOriginalDatesLoading: false,
          originalDates: result,
        });
      });
  },

  async getOriginalProviders({ commit, dispatch, rootGetters }) {
    commit('changeState', { areOriginalProvidersLoading: true });
    let result = [];
    await axios
      .get(`${rootGetters.feApi}/mapping/originals/providers`)
      .then(({ data }) => {
        result = data;
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), { root: true });
      })
      .finally(() => {
        commit('changeState', {
          areOriginalProvidersLoading: false,
          originalProviders: result,
        });
      });
  },

  async getRules(
    { commit, dispatch, rootGetters },
    { filter, sorter, limit, page, regex, lookupName, type }
  ) {
    if (!['mappings', 'requirements'].includes(type)) {
      throw new RequestError(`inadmissible type "${type}"`);
    }

    let result = [];
    let total = 0;
    commit('updateCompositeState', { itemKey: 'areRulesLoading', changed: { [type]: true } });
    await axios
      .get(
        `${rootGetters.feApi}/mapping/rules`,
        getParamsWithSerializer({
          filter,
          sorter,
          limit,
          page,
          regex,
          lookup_name: lookupName,
          type,
        })
      )
      .then(({ data }) => {
        total = data.total_record;
        result = reformRules(data.records).map((item) => ({
          ...item,
          key: [item.lookup_name, item.name, item.type].join(':'),
        }));
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), { root: true });
      })
      .finally(() => {
        commit('updateCompositeState', { itemKey: 'areRulesLoading', changed: { [type]: false } });
        commit('updateCompositeState', { itemKey: 'rulesTotalItems', changed: { [type]: total } });
        commit('updateCompositeState', {
          itemKey: 'rules',
          changed: { [type]: result },
        });
      });
  },

  async editRules({ dispatch, rootGetters }, { lookupName, lookup, type, name, patch }) {
    if (!['mappings', 'requirements'].includes(type)) {
      throw new RequestError(`inadmissible type "${type}"`);
    }

    let result = null;
    try {
      const { data } = await axios.patch(`${rootGetters.feApi}/mapping/rules/edit`, {
        lookup_name: lookupName,
        lookup,
        type,
        name,
        patch,
      });
      result = data;
    } catch (error) {
      dispatch('alert/setError', processAxiosException(error), { root: true });
    }
    return result;
  },

  async getSameRequirements(
    { commit, dispatch, rootGetters },
    { name, lookup, provider, project, dt }
  ) {
    commit('changeState', { areSameRequirementsLoading: true });
    let result = [];
    await axios
      .get(
        `${rootGetters.feApi}/mapping/diff/requirements`,
        getParamsWithSerializer({
          name,
          lookup,
          provider,
          project,
          dt,
        })
      )
      .then(({ data }) => {
        result = data;
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), { root: true });
      })
      .finally(() => {
        commit('changeState', {
          areSameRequirementsLoading: false,
          sameRequirements: result,
        });
      });
  },

  // route: originals
  async getDiffAmount({ commit, dispatch, rootGetters }, { provider, dt, project, lookup, regex }) {
    let result = [];
    commit('changeState', { areDiffAmountLoading: true });
    await axios
      .get(
        `${rootGetters.feApi}/mapping/diff`,
        getParamsWithSerializer(
          {
            provider,
            dt,
            project,
            lookup,
            regex,
          },
          'lookup'
        )
      )
      .then(({ data }) => {
        result = data;
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), { root: true });
      })
      .finally(() => {
        commit('changeState', { areDiffAmountLoading: false, diffAmount: result });
      });
  },

  async getAbsentLookups({ commit, dispatch, rootGetters }, { provider, project }) {
    commit('changeState', { areAbsentLookupsLoading: true });
    let result = [];
    await axios
      .get(
        `${rootGetters.feApi}/mapping/diff/absent`,
        getParamsWithSerializer({
          provider,
          project,
        })
      )
      .then(({ data }) => {
        result = data;
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), { root: true });
      })
      .finally(() => {
        commit('changeState', {
          areAbsentLookupsLoading: false,
          absentLookups: result,
        });
      });
  },

  async getOriginalsToAdd(
    { commit, dispatch, rootGetters },
    { provider, dt, project, lookup, regex, sorter, page, limit }
  ) {
    let result = [];
    let total = 0;
    commit('changeState', { areOriginalsToAddLoading: true });
    await axios
      .get(
        `${rootGetters.feApi}/mapping/diff/add`,
        getParamsWithSerializer(
          {
            provider,
            dt,
            project,
            lookup,
            regex,
            sorter,
            page,
            limit,
          },
          'lookup'
        )
      )
      .then(({ data }) => {
        result = reformOriginals(data.records);
        total = data.total_record;
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), { root: true });
      })
      .finally(() => {
        commit('changeState', {
          areOriginalsToAddLoading: false,
          originalsToAdd: result,
          originalsToAddTotalItems: total,
        });
      });
  },

  async getMappingsToRemove(
    { commit, dispatch, rootGetters },
    { provider, dt, project, lookup, regex, sorter, page, limit }
  ) {
    let total = 0;
    let result = [];
    commit('changeState', { areMappingsToRemoveLoading: true });
    await axios
      .get(
        `${rootGetters.feApi}/mapping/diff/remove`,
        getParamsWithSerializer(
          {
            provider,
            dt,
            project,
            lookup,
            regex,
            sorter,
            page,
            limit,
          },
          'lookup'
        )
      )
      .then(({ data }) => {
        result = reformRules(data.records).map((item) => ({
          ...item,
          key: `${item.lookup_name}#${item.name}`,
        }));
        total = data.total_record;
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), { root: true });
      })
      .finally(() => {
        commit('changeState', {
          areMappingsToRemoveLoading: false,
          mappingsToRemove: result,
          mappingsToRemoveTotalItems: total,
        });
      });
  },
};
