export default {
  lookupsHeaders: [
    {
      text: 'name',
      value: 'name',
      align: 'left',
    },
    {
      text: 'lookup',
      value: 'lookup',
      align: 'center',
    },
    {
      text: 'type',
      value: 'type',
      align: 'center',
    },
    {
      text: '',
      value: 'data-table-expand',
    },
    {
      text: 'projects',
      value: 'projects',
      align: 'center',
    },
    {
      text: 'provider',
      value: 'provider',
      align: 'center',
    },
    {
      text: 'requirements',
      value: 'requirements',
      align: 'center',
    },
    {
      text: 'dt',
      value: 'dt',
      align: 'center',
      divider: true,
    },
    {
      text: 'test request body',
      value: 'test_request_body',
      align: ' d-none',
    },
    {
      text: 'last test',
      value: 'test_response',
      align: 'center',
    },
  ],
  areLookupsLoading: false,
  lookups: [],
  lookupsTotalItems: 0,
  invokeLookupsTests: false,
  allLookupsTest: null,

  lookupNames: [],
  areLookupNamesLoading: false,
  lookupProjects: [],
  areLookupProjectsLoading: false,

  originalsHeaders: [
    {
      text: 'name',
      value: 'name',
      align: 'center',
    },
    {
      text: 'requirements',
      value: 'requirements',
      align: 'center',
    },
    {
      text: 'keywords',
      value: 'keywords',
      align: 'center',
    },
    {
      text: 'dt',
      value: 'dt',
      align: 'center',
    },
  ],
  areOriginalsLoading: false,
  originalsTotalItems: 0,
  originals: [],

  originalDates: [],
  areOriginalDatesLoading: false,
  originalProviders: [],
  areOriginalProvidersLoading: false,

  rulesHeaders: [
    {
      text: '',
      value: 'data-table-expand',
    },
    {
      text: 'name',
      value: 'name',
      align: 'center',
    },
    {
      text: 'keywords',
      value: 'keywords',
      align: 'center',
    },
    {
      text: 'matches',
      value: 'matches',
      align: 'center',
    },
    {
      text: 'excepts',
      value: 'excepts',
      align: 'center',
    },
    {
      text: 'positives',
      value: 'positives',
      align: 'center',
    },
    {
      text: 'negatives',
      value: 'negatives',
      align: 'center',
    },
    {
      text: 'dt',
      value: 'dt',
      align: 'center',
    },
  ],
  areRulesLoading: {
    mappings: false,
    requirements: false,
  },
  rulesTotalItems: {
    mappings: 0,
    requirements: 0,
  },
  rules: {
    mappings: [],
    requirements: [],
  },

  // route: originals
  diffAmountHeaders: [
    {
      text: 'lookup',
      value: 'lookup',
      align: 'center',
    },
    {
      text: 'originals count',
      value: 'originals_count',
      align: 'center',
    },
    {
      text: 'mappings count',
      value: 'mappings_count',
      align: 'center',
    },
    {
      text: 'can add',
      value: 'can_add',
      align: 'center',
    },
    {
      text: 'can remove',
      value: 'can_remove',
      align: 'center',
    },
  ],
  diffAmount: [],
  areDiffAmountLoading: false,

  originalsToAddHeaders: [
    {
      text: '',
      value: 'data-table-expand',
    },
    {
      text: 'name',
      value: 'name',
      align: 'center',
    },
    {
      text: 'requirements',
      value: 'requirements',
      align: 'center',
    },
    {
      text: 'keywords',
      value: 'keywords',
      align: 'center',
    },
    {
      text: 'same req count',
      value: 'same_req_count',
      align: 'center',
    },
  ],
  originalsToAdd: [],
  originalsToAddTotalItems: 0,
  areOriginalsToAddLoading: false,

  mappingsToRemoveHeaders: [
    {
      text: 'lookup_name',
      value: 'lookup_name',
      align: 'center',
    },
    {
      text: 'name',
      value: 'name',
      align: 'center',
    },
    {
      text: 'keywords',
      value: 'keywords',
      align: 'center',
    },
    {
      text: 'matches',
      value: 'matches',
      align: 'center',
    },
    {
      text: 'excepts',
      value: 'excepts',
      align: 'center',
    },
    {
      text: 'positives',
      value: 'positives',
      align: 'center',
    },
    {
      text: 'negatives',
      value: 'negatives',
      align: 'center',
    },
    {
      text: 'dt',
      value: 'dt',
      align: 'center',
    },
  ],
  mappingsToRemove: [],
  mappingsToRemoveTotalItems: 0,
  areMappingsToRemoveLoading: false,

  absentLookups: [],
  areAbsentLookupsLoading: false,

  sameRequirementsHeaders: [
    {
      text: 'lookup name',
      value: 'lookup_name',
      align: 'center',
    },
    {
      text: 'requirements',
      value: 'requirements',
      align: 'center',
    },
  ],
  sameRequirements: [],
  areSameRequirementsLoading: false,
};
