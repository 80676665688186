<template>
  <v-app-bar app clipped-left>
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mobile"
      @click.stop="$root.$emit('drawerToggle')"
    />
    <img src="/favicon.png" width="30" />
    <v-toolbar-title class="pl-2 title">
      <span class="text-uppercase hidden-sm-and-down">LotHub</span>
      <span class="text-none font-weight-thin subtitle-2"> v.{{ $store.getters.appVersion }} </span>
    </v-toolbar-title>
    <v-spacer />
    <div>
      <span v-show="userLoaded" class="hidden-sm-and-down">{{ userName }}</span>
      <v-menu v-if="userLogged" bottom offset-y transition="slide-y-transition">
        <template #activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template #activator="{ on: tooltip }">
              <v-btn
                class="ml-2"
                icon
                :loading="tagMakerIsLoading"
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon>mdi-sitemap</v-icon>
              </v-btn>
            </template>
            <span>Make build</span>
          </v-tooltip>
        </template>

        <v-list dense>
          <template v-for="repo in repos">
            <v-list-item :key="repo" link @click="makeBuildForRepo(repo)">
              <v-list-item-title>{{ repo }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-menu v-model="dialog" :close-on-content-click="false" :nudge-width="200" offset-y>
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-badge :value="localLogsLength" color="red" overlap dot>
              <v-icon>mdi-cogs</v-icon>
            </v-badge>
          </v-btn>
        </template>

        <v-card max-width="500">
          <v-list>
            <v-list-item link :href="getAuthLink()">
              <v-list-item-icon>
                <v-icon>mdi-{{ userLogged ? 'logout' : 'login' }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Log {{ userLogged ? 'out' : 'in' }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-theme-light-dark</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Dark theme</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-switch v-model="$vuetify.theme.dark" inset @change="saveTheme" />
              </v-list-item-action>
            </v-list-item>
            <v-list-item link href="/packages">
              <v-list-item-icon>
                <v-icon>mdi-package-variant</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Packages</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <div v-if="userLogged">
              <v-subheader v-show="localLogsLength">
                Last notifications
                <v-spacer />
                <v-btn color="warning" small text @click="cleanAndClose()"> clean </v-btn>
                <v-badge color="red" :content="localLogsLength" overlap :value="localLogsLength">
                  <v-btn color="primary" small text to="/notifications" @click="dialog = false">
                    Show All
                  </v-btn>
                </v-badge>
              </v-subheader>
              <template v-for="(item, index) in firstThreeLogs">
                <v-divider :key="index" />
                <v-list-item :key="item.timestamp + index">
                  <v-list-item-content>
                    <v-list-item-title>
                      <code class="grey lighten-2 black--text">{{ item.response }}</code>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ item.responseURL }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text>{{ item.timestamp }}</v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </div>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AppBar',
  data: () => ({
    dialog: false,
    repos: ['datacollector', 'lothub', 'feldjaeger'],
  }),
  computed: {
    ...mapState('user', ['userName', 'userLogged', 'userLoaded', 'versions', 'tagMakerIsLoading']),

    localLogsLength() {
      return this.$store.getters.localLogs.length;
    },
    firstThreeLogs() {
      return this.$store.getters.localLogs.slice(0, 3);
    },
  },
  methods: {
    ...mapActions('alert', ['cleanNotifications']),
    ...mapActions('user', ['makeBuild']),

    cleanAndClose() {
      this.dialog = false;
      this.cleanNotifications();
    },
    saveTheme(isDark) {
      this.$store.commit('updateLocalSettings', { isDark });
    },
    async makeBuildForRepo(repo) {
      await this.$root.$confirm
        .open('Confirm', `Are you sure you want to make tag for ${repo}?`, {
          width: 400,
        })
        .then(
          async () => {
            if (await this.makeBuild({ repo })) {
              await this.$store.dispatch('alert/setAlert', {
                msg: `Build for ${repo} made successfully`,
              });
            } else {
              await this.$store.dispatch('alert/setError', {
                status: 531,
                response:
                  'Something went wrong, look in console for details. Probably token is expired',
                responseURL: 'https://ci.lotint.net/',
              });
            }
          },
          () => {}
        );
    },
    getAuthLink() {
      const time = new Date().getTime();
      return this.userLogged ? `/user/logout?t=${time}` : `/user/login?t=${time}`;
    },
  },
};
</script>
