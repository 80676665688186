const state = {
  customerCountHeaders: [
    {
      text: 'date',
      value: 'date',
      align: 'left',
    },
    {
      text: 'cnt',
      value: 'cnt',
      align: 'right',
      sortable: false,
    },
    {
      text: 'first_quartile',
      value: 'first_quartile',
      align: 'right',
      sortable: false,
    },
    {
      text: 'median',
      value: 'median',
      align: 'right',
      sortable: false,
    },
    {
      text: 'third_quartile',
      value: 'third_quartile',
      align: 'right',
      sortable: false,
    },
    {
      text: 'iqr',
      value: 'iqr',
      align: 'right',
      sortable: false,
    },
  ],

  objectCountHeaders: [
    {
      text: 'date',
      value: 'date',
      align: 'left',
    },
    {
      text: 'expected',
      value: 'expected',
      align: 'right',
      sortable: false,
    },
    {
      text: 'fast_cnt',
      value: 'fast_cnt',
      align: 'right',
      sortable: false,
    },
    {
      text: 'percent',
      value: 'percent',
      align: 'right',
      sortable: false,
    },
    {
      text: 'dupl_cnt',
      value: 'dupl_cnt',
      align: 'right',
      sortable: false,
    },
    {
      text: 'dupl %',
      value: 'duplicates_percent',
      align: 'right',
      sortable: false,
    },
    {
      text: 'full %',
      value: 'full_percent',
      align: 'right',
      sortable: false,
    },
  ],

  statPortals: [],
  statPortalsTotalItems: 0,
  areStatPortalsLoading: false,
  exportValues: [],

  isDailyReportLoading: false,

  customerCount: [],
  customerCountTotal: 0,

  objectCount: [],
  objectCountTotal: 0,

  isObjectCountLastLoading: false,
  objectCountLast: [],
};

export default state;
