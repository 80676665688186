import moment from 'moment';
import cloneDeep from 'clone-deep';

const actions = {
  hideAlert({ commit, dispatch }) {
    commit('changeState', { show: false });
    setTimeout(() => dispatch('setAlert'), 300);
  },

  setAlert({ commit }, { msg = '', isError = false } = {}) {
    commit('changeState', { show: !!msg, isError, msg });
  },

  setError({ commit, dispatch, state }, { status, response, responseURL }) {
    const logRecord = {
      timestamp: moment(new Date().toISOString()).format('YYYY-MM-DD HH:mm:ss'),
      status,
      response,
      responseURL,
    };
    commit('changeState', {
      show: status || response,
      isError: true,
      status,
      msg: status,
      body: response,
    });
    if (response) {
      const { logs } = cloneDeep(state);
      logs.unshift(logRecord);
      commit('changeState', { logs });
      dispatch('setLocalLogs');
    }
  },

  getLocalLogs({ commit }) {
    let logs;
    try {
      logs = JSON.parse(localStorage.getItem('localLogs') || '[]');
      // eslint-disable-next-line no-empty
    } catch (error) {}
    commit('changeState', { logs: logs || [] });
  },

  setLocalLogs({ rootGetters }) {
    localStorage.setItem('localLogs', JSON.stringify(rootGetters.localLogs));
  },

  cleanNotifications({ commit }) {
    localStorage.removeItem('localLogs');
    commit('changeState', { logs: [] });
  },
};

export default actions;
