const state = {
  leipzigStatHeaders: [
    {
      text: 'Country',
      value: 'country',
      align: 'center',
    },
    {
      text: '',
      value: 'data-table-expand',
    },
    {
      text: 'Total ads',
      value: 'cnt_ads',
      align: 'center',
    },
    {
      text: 'Total acc',
      value: 'cnt_acc',
      align: 'center',
    },
    {
      text: 'Updated',
      value: 'updated',
      align: 'center',
    },
    {
      text: 'Count ads before matching',
      value: 'ads_before_matching',
      align: 'center',
    },
    {
      text: 'Count ads after matching',
      value: 'ads_after_matching',
      align: 'center',
    },
    {
      text: 'Count commercial acc before matching',
      value: 'commercial_acc_before_matching',
      align: 'center',
    },
    {
      text: 'Count commercial acc after matching',
      value: 'commercial_acc_after_matching',
      align: 'center',
    },
    {
      text: 'Count private acc before matching',
      value: 'private_acc_before_matching',
      align: 'center',
    },
    {
      text: 'Count private acc after matching',
      value: 'private_acc_after_matching',
      align: 'center',
    },
    {
      text: 'Date of matching',
      value: 'matching_dt',
      align: 'center',
    },
    {
      text: 'action',
      value: 'action',
      sortable: false,
      align: 'center',
    },
  ],

  leipzigStatTotalItems: 0,
  leipzigStat: [],
  isLeipzigStatLoading: false,

  leipzigStatHeadersByPortal: [
    {
      text: 'Portal',
      value: 'portal',
      align: 'center',
    },
    {
      text: 'Count ads',
      value: 'cnt_ads',
      align: 'center',
    },
    {
      text: 'Count acc',
      value: 'cnt_acc',
      align: 'center',
    },
    {
      text: 'Date start',
      value: 'start',
      align: 'center',
    },
    {
      text: 'Date end',
      value: 'finish',
      align: 'center',
    },
  ],

  leipzigStatByPortal: [],
  isLeipzigStatByPortalLoading: false,

  leipzigStatHeadersByDealerType: [
    {
      text: 'Dealer type',
      value: 'dealer_type',
      align: 'center',
    },
    {
      text: 'Count',
      value: 'cnt',
      align: 'center',
    },
    {
      text: 'Percent of all',
      value: 'percent',
      align: 'center',
    },
  ],

  leipzigStatByDealerType: [],
  isLeipzigStatByDealerTypeLoading: false,
};

export default state;
