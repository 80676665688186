import moment from 'moment';

import axios from '@/plugins/axios';
import { getParamsWithSerializer, reformRecords, processAxiosException } from '@/plugins/mixin';

const actions = {
  async getAdressbuch(
    { commit, dispatch, rootGetters },
    { filter, sorter, limit, page, regex } = {}
  ) {
    commit('changeState', { isAdressbuchLoading: true });
    await axios
      .get(
        `${rootGetters.feApi}/dqm/adressbuch`,
        getParamsWithSerializer({
          filter,
          sorter,
          limit: limit || 1000,
          page,
          regex,
        })
      )
      .then(({ data }) => {
        const objects = reformRecords(data.records, {
          updated: (value) => (value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''),
        }).map((el) => {
          const item = el;
          item.id = `${item.portal}_${item.object_type}`;
          return item;
        });
        commit('changeState', { adressbuch: objects, adressbuchTotalItems: data.total_record });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), { root: true });
      });
    commit('changeState', { isAdressbuchLoading: false });
  },
};

export default actions;
