import Vue from 'vue';

const VueCopyTable = {
  install() {
    // eslint-disable-next-line func-names
    Vue.prototype.$copyTable = function (items, headers, sortKey) {
      const letters = [];
      let buffer = '';
      headers.forEach(() => {
        letters.push('--');
      });
      buffer = `${letters.join(' | ')}\n`;
      items.sort((a, b) => {
        if (a[sortKey] < b[sortKey]) {
          return -1;
        }
        if (a[sortKey] > b[sortKey]) {
          return 1;
        }
        return 0;
      });
      if (items.length) {
        items.forEach((item) => {
          const copied = [];
          headers.forEach((header) => {
            copied.push(item[header]);
          });
          buffer += `${copied.join(' | ')}\n`;
        });
      }
      return items.length ? `${headers.join(' | ')}\n${buffer}` : '';
    };
  },
};

Vue.use(VueCopyTable);

export default VueCopyTable;
