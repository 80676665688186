export default {
  state: {
    packageVersion: process.env.VUE_APP_VERSION,
    feApi: '/api/fe/v2',
    zwApi: '/api/zw/v1',
    gwApi: '/api/gw/v1',
    alysonApi: '/api/alyson/v1',
    wolkeAPI: '/api/wolke/v1',
    minAPITimeout: 1000,
    localSettings: {},
  },
  mutations: {
    changeState: (state, changed) => {
      Object.entries(changed).forEach(([name, value]) => {
        state[name] = value;
      });
    },
    updateCompositeState: (state, { itemKey, changed }) => {
      Object.entries(changed).forEach(([name, value]) => {
        state[itemKey][name] = value;
      });
    },
  },
};
