<template>
  <v-navigation-drawer
    v-if="userLogged"
    v-model="drawer"
    app
    clipped
    width="300"
    :expand-on-hover="!this.$vuetify.breakpoint.mobile"
    :mini-variant="!this.$vuetify.breakpoint.mobile"
  >
    <v-list dense expand>
      <template v-for="tab in tabs">
        <v-list-group v-if="tab.children" :key="tab.path" no-action>
          <template #prependIcon>
            <v-badge
              :value="Boolean(tab.meta.status)"
              :icon="tab.meta.status"
              overlap
              color="warning"
            >
              <v-icon>{{ tab.meta.icon }}</v-icon>
            </v-badge>
          </template>
          <template #activator>
            <v-list-item class="pl-0">
              <v-list-item-title class="font-weight-bold">
                {{ tab.meta.title }}
              </v-list-item-title>
            </v-list-item>
          </template>

          <template v-for="child in tab.children">
            <v-list-item
              v-if="!child.meta.invisible"
              :key="child.path"
              :to="child.meta.redirect ? null : child.path"
              :href="child.meta.redirect ? child.meta.redirect : null"
              link
              @click="toTop()"
            >
              <v-list-item-title>
                <span v-text="child.meta.title" />
              </v-list-item-title>
              <v-list-item-icon>
                <v-icon>{{ child.meta.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </v-list-group>
      </template>
      <v-list-group prepend-icon="mdi-information-outline" no-action>
        <template #activator>
          <v-list-item class="pl-0">
            <v-list-item-title class="font-weight-bold"> Info </v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item
          v-for="link in infoLinks"
          :key="link.to || link.url"
          :to="link.to"
          :href="link.url"
          :target="link.target || undefined"
          link
          @click="toTop()"
        >
          <v-list-item-title>
            <span :class="link.class || null" v-text="link.title" />
            <span
              v-if="link.version"
              class="pl-1 text-lowercase text-none font-weight-thin text-caption"
              v-text="`v. ${versions[link.version]}`"
            />
          </v-list-item-title>
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NavigationDrawer',
  data: () => ({
    drawer: true,
    tabs: [],
  }),
  computed: {
    ...mapState('user', ['versions', 'userLogged', 'infoLinks']),
  },
  created() {
    this.tabs = this.$router.options.routes.filter(({ children }) => children);
  },
  mounted() {
    this.$root.$on('drawerToggle', () => {
      this.drawer = !this.drawer;
    });
  },
  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
