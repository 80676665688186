const state = {
  show: false,
  isError: false,
  msg: '',
  status: '',
  body: '',
  logs: [],
};

export default state;
