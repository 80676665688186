import axios from '@/plugins/axios';
import { filterObject, processAxiosException } from '@/plugins/mixin';

const actions = {
  async readFarms({ commit, dispatch, rootGetters }) {
    commit('changeState', { areFarmsLoading: true });
    await axios
      .get(`${rootGetters.zwApi}/farm`)
      .then(({ data }) => {
        commit('changeState', { farms: data.data, farmsTotalItems: data.len });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { areFarmsLoading: false });
  },

  async repairFarm({ commit, dispatch, rootGetters }, { label }) {
    commit('changeState', { areFarmsLoading: true });
    await axios.delete(`${rootGetters.zwApi}/farm/${label}?repair=1`).catch((error) => {
      dispatch('alert/setError', processAxiosException(error), {
        root: true,
      });
    });
    dispatch('readFarms');
  },

  async deleteFarm({ commit, dispatch, rootGetters }, { label }) {
    commit('changeState', { areFarmsLoading: true });
    await axios.delete(`${rootGetters.zwApi}/farm/${label}`).catch((error) => {
      dispatch('alert/setError', processAxiosException(error), { root: true });
    });
    dispatch('readFarms');
  },

  async createFarmLock({ dispatch, rootGetters }, { label, name, description, duration }) {
    await axios
      .post(`${rootGetters.zwApi}/farm/${label}/lock`, {
        name,
        description,
        duration: parseInt(duration, 10),
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    dispatch('readFarms');
  },

  async deleteFarmLock({ commit, dispatch, rootGetters }, { label, name }) {
    commit('changeState', { areFarmsLoading: true });
    await axios.delete(`${rootGetters.zwApi}/farm/${label}/lock/${name}`).catch((error) => {
      dispatch('alert/setError', processAxiosException(error), {
        root: true,
      });
    });
    dispatch('readFarms');
  },

  async createFarm({ commit, dispatch, rootGetters }, { label, group, provider, pillar }) {
    commit('changeState', { newFarmIsLoading: true });
    await axios
      .post(
        `${rootGetters.zwApi}/farm`,
        filterObject({
          label,
          group,
          provider,
          pillar,
        })
      )
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { newFarmIsLoading: false });
    dispatch('readFarms');
  },
};

export default actions;
