const state = {
  adressbuchHeaders: [
    {
      text: 'project',
      value: 'project',
      align: 'center',
    },
    {
      text: 'portal',
      value: 'portal',
      align: 'left',
    },
    {
      text: 'object_type',
      value: 'object_type',
      align: 'center',
    },
    {
      text: 'cnt_raw',
      value: 'cnt_raw',
      align: 'center',
    },
    {
      text: 'cnt_raw_not_fill',
      value: 'cnt_raw_not_fill',
      align: 'center',
    },
    {
      text: 'cnt_country_null',
      value: 'cnt_country_null',
      align: 'center',
    },
    {
      text: 'cnt_addr',
      value: 'cnt_addr',
      align: 'center',
    },
    {
      text: 'cnt_new',
      value: 'cnt_new',
      align: 'center',
    },
    {
      text: 'cnt_addr_null',
      value: 'cnt_addr_null',
      align: 'center',
    },
    {
      text: 'cnt_need_run',
      value: 'cnt_need_run',
      align: 'center',
    },
    {
      text: 'updated',
      value: 'updated',
      align: 'center',
    },
  ],

  adressbuch: [],
  adressbuchTotalItems: 0,
  isAdressbuchLoading: false,
};

export default state;
