import axios from '@/plugins/axios';
import { getParamsWithSerializer, reformDateKey, processAxiosException } from '@/plugins/mixin';

const actions = {
  async getObjectCount({ commit, dispatch, rootGetters }, { portal, dateFrom, dateTo, num }) {
    commit('changeState', { objectCount: [], objectCountTotal: 0 });
    commit('changeState', { isDailyReportLoading: true });
    await axios
      .get(
        `${rootGetters.feApi}/dqm/objectcount`,
        getParamsWithSerializer({
          portal,
          datefrom: dateFrom,
          dateto: dateTo,
          num,
          sorter: 'date',
          limit: 1000,
        })
      )
      .then(({ data }) => {
        commit('changeState', {
          objectCount: reformDateKey(data.records),
          objectCountTotal: data.total_record,
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { isDailyReportLoading: false });
  },

  async getObjectCountLast({ commit, dispatch, rootGetters }, { portal, num }) {
    commit('changeState', { objectCountLast: [] });
    commit('changeState', { isObjectCountLastLoading: true });
    await axios
      .get(
        `${rootGetters.feApi}/dqm/objectcount`,
        getParamsWithSerializer({
          portal,
          num,
          result: 1,
          limit: 5,
          sorter: 'date+desc',
        })
      )
      .then(({ data }) => {
        commit('changeState', { objectCountLast: reformDateKey(data.records) });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { isObjectCountLastLoading: false });
  },

  async getCustomerCount({ commit, dispatch, rootGetters }, { portal, dateFrom, dateTo, num }) {
    commit('changeState', { customerCount: [], customerCountTotal: 0 });
    commit('changeState', { isDailyReportLoading: true });
    await axios
      .get(
        `${rootGetters.feApi}/dqm/customercount`,
        getParamsWithSerializer({
          portal,
          datefrom: dateFrom,
          dateto: dateTo,
          num,
          sorter: 'date',
          limit: 1000,
        })
      )
      .then(({ data }) => {
        commit('changeState', {
          customerCount: reformDateKey(data.records),
          customerCountTotal: data.total_record,
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { isDailyReportLoading: false });
  },

  async getStatPortals({ commit, dispatch, rootGetters }) {
    commit('changeState', { areStatPortalsLoading: true });
    await axios
      .get(`${rootGetters.feApi}/dqm/portal`)
      .then(({ data }) => {
        commit('changeState', {
          statPortals: data.records,
          statPortalsTotalItems: data.total_record,
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { areStatPortalsLoading: false });
  },

  async getExportNums({ commit, dispatch, rootGetters }) {
    await axios
      .get(`${rootGetters.feApi}/dqm/nums`)
      .then(({ data }) => {
        commit('changeState', { exportValues: data.records });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
  },
};

export default actions;
