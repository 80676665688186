import axios from 'axios';
import store from '../store';

const axiosInstance = axios.create({
  baseURL: '',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    AuthToken: '',
  },
});

axiosInstance.interceptors.request.use(async (request) => {
  console.log(request);
  if (store.state.user.userLogged && !!request && request.url.startsWith('/api')) {
    request.headers.Authorization = `Bearer ${store.state.user.token}`;
  }
  return request;
});

export default axiosInstance;
