const state = {
  currentStatusHeaders: [
    {
      text: 'portal',
      value: 'portal',
      align: 'left',
    },
    {
      text: '',
      value: 'data-table-expand',
    },
    {
      text: 'exp_ytd',
      value: 'exp_ytd',
      align: 'center',
    },
    {
      text: 'diff',
      value: 'diff',
      align: 'center',
    },
    {
      text: 'expected',
      value: 'expected',
      align: 'center',
    },
    {
      text: 'parsed',
      value: 'parsed',
      align: 'center',
    },
    {
      text: 'fast_cnt',
      value: 'fast_cnt',
      align: 'center',
    },
    {
      text: 'dupl cnt',
      value: 'duplicates_cnt',
      align: 'center',
    },
    {
      text: 'dupl %',
      value: 'duplicates_percent',
      align: 'center',
    },
    {
      text: 'fast %',
      value: 'fast_percent',
      align: 'center',
    },
    {
      text: 'full %',
      value: 'full_percent',
      align: 'center',
    },
    {
      text: 'status',
      value: 'status',
      align: 'center',
      class: 'px-1',
      sortable: false,
    },
    {
      text: 'priority',
      align: 'center',
      value: 'priority',
      sortable: false,
    },
    {
      text: 'exports',
      value: 'esort',
      align: 'center',
      class: 'px-1',
    },
    {
      text: 'msd_full',
      value: 'msd_full',
      align: 'center',
    },
    {
      text: 'msd_cmr',
      value: 'msd_cmr',
      align: 'center',
    },
    {
      text: 'updated',
      value: 'max_updated',
      align: 'center',
    },
    {
      text: 'actions',
      align: 'center',
      sortable: false,
      value: 'action',
    },
  ],

  currentStatus: [],
  currentStatusTotalItems: 0,
  isCurrentStatusLoading: false,
};

export default state;
