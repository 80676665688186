import moment from 'moment';

import axios from '@/plugins/axios';
import { getParamsWithSerializer, reformRecords, processAxiosException } from '@/plugins/mixin';

const actions = {
  async getCurrentStatus(
    { commit, dispatch, rootGetters, rootState },
    { filter, sorter, limit, page, regex } = {}
  ) {
    await dispatch('wolke/getWolkePortals', {}, { root: true });
    commit('changeState', { isCurrentStatusLoading: true });
    await axios
      .get(
        `${rootGetters.feApi}/dqm/stat`,
        getParamsWithSerializer({
          filter,
          sorter,
          limit: limit || 1000,
          page,
          regex,
        })
      )
      .then(({ data }) => {
        const records = reformRecords(data.records, {
          diff: (value) => value,
          crit_status: (value) => value,
          run_status: (value) => value,
          num: (value) => value,
          esort: (value) => value,
          max_updated: (value) => (value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''),
        });
        commit('changeState', {
          currentStatusTotalItems: data.total_record,
          currentStatus: records.map((item) => {
            const current = item;
            const found = rootState.wolke.wolkePortals.find(
              (searchItem) =>
                searchItem.portal === item.portal ||
                searchItem.portal === item.portal.replace(/^auto_/, 'auto.')
            );
            if (found) {
              current.status = found.status;
              current.priority = found.starts_priority;
            }
            return current;
          }),
        });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { isCurrentStatusLoading: false });
  },
};

export default actions;
