const state = {
  farmsTableHeaders: [
    {
      text: 'Label',
      value: 'label',
    },
    {
      text: '',
      value: 'data-table-expand',
    },
    {
      text: 'Provider',
      value: 'provider',
    },
    {
      text: 'Group',
      value: 'group',
    },
    {
      text: 'CC',
      value: 'comments.length',
    },
    {
      text: 'Status',
      value: 'status',
    },
    {
      text: 'Created',
      value: 'created',
    },
    {
      text: 'Duration',
      value: 'created_h',
    },
    {
      text: 'Updated',
      value: 'updated',
    },
    {
      text: 'Duration',
      value: 'updated_h',
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ],

  farms: [],
  farmsTotalItems: 0,
  areFarmsLoading: false,

  detailsTableHeaders: [
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'Description',
      value: 'description',
    },
    {
      text: 'Until',
      value: 'until',
    },
    {
      text: 'Hours remains',
      value: 'hremains',
    },
    {
      text: 'Action',
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ],

  newFarmIsLoading: false,
};

export default state;
