import qs from 'qs';
import moment from 'moment';

import router from '../router/index';

function getPrettyNumber(num) {
  if (typeof num === 'number') {
    let str = num.toString();
    const sign = str.match(/^([+-])\d+/) ? str.match(/^([+-])\d+/)[1] : '';
    const ending = str.match(/(\..+)/) ? str.match(/(\..+)/)[1] : '';
    str = str.replace(ending, '').replace(sign, '');
    let res = '';
    for (let x = 0; x < str.length; x += 1) {
      const sym = str[str.length - 1 - x];
      res = x % 3 === 0 && x !== 0 ? `${sym},${res}` : `${sym}${res}`;
    }
    return sign + res + ending;
  }
  return num;
}

function reformRecords(source, args = {}, usePrettyInteger = true) {
  const result = [];
  for (let i = 0; i < source.length; i += 1) {
    const item = {};
    Object.entries(source[i]).forEach(([srcKey, srcVal]) => {
      let val = srcVal;
      Object.entries(args).forEach(([argKey, func]) => {
        if (argKey === srcKey) {
          val = func(srcVal);
        }
      });
      if (usePrettyInteger && !Object.keys(args).includes(srcKey)) {
        val = getPrettyNumber(srcVal);
      }
      item[srcKey] = val;
    });
    result.push(item);
  }
  return result;
}

function updateUrlQuery(queryDict) {
  const { path } = router.currentRoute;
  let { query } = router.currentRoute;
  query = { ...query, ...queryDict };
  Object.filter = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate));
  router
    .push({
      path,
      // eslint-disable-next-line no-unused-vars
      query: Object.filter(query, ([_, value]) => value && value !== 'false'),
    })
    .catch(() => {});
}

const getParamsWithSerializer = (dict, queryFilter = 'filter') => ({
  params: Object.prototype.hasOwnProperty.call(dict, queryFilter)
    ? { ...dict, [queryFilter]: dict[queryFilter] ? encodeURIComponent(dict[queryFilter]) : '' }
    : dict,
  paramsSerializer(params) {
    return qs.stringify(params, {
      encode: false,
      filter: (prefix, value) => {
        if (!value && typeof value !== 'boolean') {
          return undefined;
        }
        return value;
      },
    });
  },
});

const reformDateKey = (items) =>
  items.map((item) => {
    const current = item;
    current.date = moment(item.date).format('YYYY-MM-DD');
    return current;
  });

// method considers following values as empty:
// entities which cannot be expressed like JSON,
// entities which do not contain any real value,
// sequences which has not any value

// JSON.stringify returns undefined for these entities:
// function, null, undefined, promise
// also JSON.stringify returns "{}" for these entities:
// Map, Set, Object, Class
const isEmpty = (value) =>
  !value ||
  Number.isNaN(value) ||
  Infinity === value ||
  -Infinity === value ||
  value == null ||
  [null, undefined, '{}', '[]'].includes(JSON.stringify(value));

// eslint-disable-next-line no-unused-vars
const filterObject = (
  obj,
  callback = (value, _) => typeof value === 'boolean' || !isEmpty(value)
) => Object.fromEntries(Object.entries(obj).filter(([key, val]) => callback(val, key)));

class RequestError extends Error {}

const processAxiosException = (error) => {
  if (error.request && 'status' in error.request) {
    const { status, response, responseURL } = error.request;
    return { status, response, responseURL };
  }
  throw error;
};

const camelCase = (str) =>
  typeof str === 'string' &&
  str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return '';
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });

const validateJson = (string) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return { value: false, msg: e.message };
  }
  return { value: true };
};

export {
  getPrettyNumber,
  reformRecords,
  updateUrlQuery,
  getParamsWithSerializer,
  reformDateKey,
  isEmpty,
  filterObject,
  processAxiosException,
  RequestError,
  camelCase,
  validateJson,
};
