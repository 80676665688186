import Vue from 'vue';
import Vuex from 'vuex';

import { createStore } from 'vuex-extensions';

/** Store modules */
import fields2 from './modules/fields2';
import user from './modules/user';
import alert from './modules/alert';
import currentStatus from './modules/currentStatus';
import dailyReport from './modules/dailyReport';
import zoowarter from './modules/zoowarter';
import adressbuch from './modules/adressbuch';
import alyson from './modules/alyson';
import leipzigStat from './modules/leipzigStat';
import wolke from './modules/wolke';
import mapping from './modules/mapping';

import mixins from './mixins';

Vue.use(Vuex);

export default createStore(Vuex.Store, {
  strict: process.env.NODE_ENV !== 'production',
  state: { ...mixins.state },
  getters: {
    appVersion: (state) => state.packageVersion,
    feApi: (state) => state.feApi,
    zwApi: (state) => state.zwApi,
    gwApi: (state) => state.gwApi,
    alysonApi: (state) => state.alysonApi,
    githubAmountRoutes: (state) => Object.keys(state.github.ticketsAmount),
    localLogs: (state) => state.alert.logs,
    APITimeout: (state) => state.minAPITimeout,
    wolkeAPI: (state) => state.wolkeAPI,
    localSettings: (state) => state.localSettings,
  },
  mutations: {
    updateLocalSettings(state, changed) {
      Object.entries(changed).forEach(([name, value]) => {
        state.localSettings[name] = value;
      });
      localStorage.setItem('localSettings', JSON.stringify(state.localSettings));
    },
  },
  actions: {
    getLocalSettings({ commit }) {
      commit('updateLocalSettings', {
        ...JSON.parse(localStorage.getItem('localSettings') || '{}'),
      });
    },
  },
  mixins: { mutations: mixins.mutations },
  modules: {
    fields2,
    user,
    alert,
    currentStatus,
    dailyReport,
    zoowarter,
    adressbuch,
    wolke,
    alyson,
    leipzigStat,
    mapping,
  },
});
